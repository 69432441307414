
import {
  defineComponent,
  getCurrentInstance,
  onMounted,
  reactive,
  toRefs,
  ref,
} from "vue";
import { mainServer } from "@/apiServer/installServer";
import CommonFrom from "@/components/renderComponents/commonFrom/CommonFrom.vue";
export default defineComponent({
  name: "addClass",
  components: {
    CommonFrom,
  },
  setup(props, context) {
    const { proxy }: any = getCurrentInstance();
    const refsCommonFrom: any = ref(null);
    let data: any = reactive({
      commonFromConfig: [
        [
          {
            span: 24,
            type: "input",
            BindingValues: "system_class_name",
            BindingLabels: "分类名称",
            placeholder: "请输入分类名称",
            NumberOfWords: 20,
            rules: [
              {
                required: true,
                message: "请输入分类名称",
                trigger: "blur",
              },
            ],
            annotation: "分类名称，是您建立附件应用时候所选的分类。",
          },
        ],
        [
          {
            span: 24,
            type: "textarea",
            BindingValues: "system_class_remark",
            BindingLabels: "分类备注",
            placeholder: "请书写分类备注",
            row: 5,
            NumberOfWords: 200,
            rules: [
              {
                required: true,
                message: "请填写分类备注",
                trigger: "blur",
              },
            ],
            annotation: "请填写此分类的用途",
          },
        ],
      ],
      commonFromAnnotation: "请注意，此表单为一次行提交，保存之后不可再更改。",
      Loading: false,
    });
    function FnExportFrom(params: any) {
      console.log("FnExportFrom params ---->", params);
    }
    async function FnSaveFrom() {
      const params = await refsCommonFrom.value.FnExportFrom();
      FnAddClassApi(params);
      
    }
    async function FnAddClassApi(params:any) {
      data.Loading = true;
      const res = await mainServer.AddClassApi(params, true);
      data.Loading = false;
      if (res.data.stateMsg === "success") {
        proxy.$CommonFun.FnCommonMsg(res.data.data.res, "success");
        context.emit("addClassClose", false);
      } else {
        proxy.$CommonFun.FnCommonMsg(res.data.data.res, "error");
      }
    }
    function FnApplicationNameBlur(event: any, params: any) {
      console.log("event ---->", event);
      console.log("params ---->", params);
    }
    onMounted(() => {});
    return {
      ...toRefs(data),
      proxy,
      FnExportFrom,
      refsCommonFrom,
      FnSaveFrom,
    };
  },
});


import tableList from "@/components/renderComponents/tableList/tableList.vue";
import addClass from "@/views/feature/addClass/addClass.vue";
import {
  defineComponent,
  getCurrentInstance,
  onMounted,
  reactive,
  toRefs,
} from "vue";
import { mainServer } from "@/apiServer/installServer";
import { ElMessageBox, ElMessage } from "element-plus";
export default defineComponent({
  name: "ClassList",
  components: {
    tableList,
    addClass,
  },
  setup() {
    const { proxy }: any = getCurrentInstance();
    let data: any = reactive({
      IsCollapse: true,
      tableListFalg: false,
      keyword: "",
      isShowAddApp: false,
      listData: {
        per_page: 10,
        current_page: 1,
        total: 0,
        data: [],
        keyWord: "",
        config: [
          {
            label: "分类名",
            att: "system_class_name",
            width: "200",
            type: "string",
          },
          {
            label: "备注",
            att: "system_class_remark",
            width: "/",
            type: "string",
          },
          {
            label: "操作",
            att: "operation",
            width: "120",
            type: "button",
          },
        ],
      },
    });
    function FnChangeTableListModel(falg: Boolean) {
      data.tableListFalg = falg;
      console.log(
        "FnChangeTableListModel: data.tableListFalg",
        data.tableListFalg
      );
    }
    function FnAddClassClose(value: boolean) {
      data.isShowAddApp = value;
      FnGetAddClassApi();
    }
    function FnRowDelect(item: any) {
      console.log("FnRowDelect: item ---->", item);
      ElMessageBox.confirm("您确定删除此条信息么？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          FnDelectClassApi(item.system_class_id);
        })
        .catch(() => {});
    }
    function FnRowEdit(item: any) {
      console.log("FnRowEdit: item ---->", item);
    }
    function FnCurrentChange(e: any) {
      data.listData.current_page = e;
      FnGetAddClassApi();
    }
    async function FnDelectClassApi(item: any) {
      const params = {
        system_class_id: item,
      };
      const res = await mainServer.DelectClassApi(params, true);
      console.log("FnDelectClassApi: res ---->", res);
      if (res.data.stateMsg === "success") {
        proxy.$CommonFun.FnCommonMsg(res.data.data.res, "success");
        FnGetAddClassApi();
      } else {
        proxy.$CommonFun.FnCommonMsg(res.data.data.res, "error");
      }
    }
    async function FnGetAddClassApi() {
      const res = await mainServer.GetAddClassApi(data.listData, true);
      console.log("FnGetAddClassApi: res ---->", res);
      if (res.data.stateMsg === "success") {
        data.listData.data = res.data.data.res.data.map((val: any) => {
          val.operation = [
            {
              name: "删除",
              type: "error",
              fn: FnRowDelect,
            },
            {
              name: "编辑",
              type: "primary",
              fn: FnRowEdit,
            },
          ];
          return val;
        });
        data.listData.total = res.data.data.res.total;
      } else {
        proxy.$CommonFun.FnCommonMsg(res.data.data.res, "error");
      }
    }
    onMounted(() => {
      FnGetAddClassApi();
    });
    return {
      ...toRefs(data),
      proxy,
      FnChangeTableListModel,
      FnAddClassClose,
      FnCurrentChange,
      FnGetAddClassApi,
    };
  },
});
